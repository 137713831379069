var CryptoJS = require("crypto-js");
$(document).on("turbolinks:load", function() {
  // show -> edit, click may call multiple times
  //$(document).on('click','a.encrypt-text-id',function (e) {
  $('a.encrypt-text-id').on('click',function (e) {
    console.log("encrypt-text-id clicked")
    // prevent the default click action
    e.preventDefault();

    // In form ? 
    let isf = $(this).attr('is-form')=="true"
    console.log(`isf=${isf}`)

    // Get content
    let tid = $(this).attr('text-id'); 
    console.log(`TID=${tid}`)
    var text = isf ? $("#"+tid).val() : $("#"+tid).text()
    console.log(`content=${text}`)
    if(text.length==0){
      alert("Empty content")
      return;
    }

    // Get Password
    let pid = $(this).attr('password-id'); 
    console.log(`PID=${pid}`)
    var password= $("#"+pid).val()
    console.log(`password=${password}`)
    if(password.length==0){
      alert("Empty password")
      return;
    }

    // Encrypt
    var encrypted = CryptoJS.AES.encrypt(text, password);
    console.log(`encrypted=${encrypted}`)
    //console.log(`encrypted=${encrypted.toString()}`)

    // Update
    if(isf){
      $("#"+tid).val(encrypted); 
    } else { 
      $("#"+tid).text(encrypted); 
    }  
  });

  //$(document).on('click','a.decrypt-text-id',function (e) {
  $('a.decrypt-text-id').on('click',function (e) {
    console.log("decrypt-text-id clicked")
    // prevent the default click action
    e.preventDefault();

    // In form ? 
    let isf = $(this).attr('is-form')=="true"
    console.log(`isf=${isf}`)

    // Get content
    let tid = $(this).attr('text-id'); 
    console.log(`TID=${tid}`)
    var text = isf ? $("#"+tid).val() : $("#"+tid).text()
    console.log(`content=${text}`)

    // Get Password
    let pid = $(this).attr('password-id'); 
    console.log(`PID=${pid}`)
    var password= $("#"+pid).val()
    console.log(`password=${password}`)

    // Decrypt
    var decrypted = CryptoJS.AES.decrypt(text, password); 
    //console.log(`decrypted=${decrypted}`)
    var plaintext = decrypted.toString(CryptoJS.enc.Utf8);
    console.log(`plaintext=${plaintext}`)
    if(plaintext.length==0){
      alert("Incorrect password")
      return;
    }

    // Update 
    if(isf){
      $("#"+tid).val(plaintext); 
    } else { 
      $("#"+tid).html(plaintext.replace(/\n/g,'<br>')); 
      //$("#"+tid).html(`<pre>${plaintext}</pre>`); 
    }  
  });
});
