import $ from 'jquery' // must import jquery
$(document).on("turbolinks:load", function() {
  $('.tag-input').select2({
    tags: true,  
    multiple: true,
    theme: 'bootstrap',
    tokenSeperators: [','],
    //language: $('html').attr('lang'),
    placeholder: 'Please enter your tags',
    minimumInputLength: 1,
    ajax: {
      url: "/tags.json",
      dataType: 'json',
      data: function (params) {
        return { term: params.term };
      },
      processResults: function (tags) {
        console.log(tags)
        var tlist = $.map(tags, function (tag) {
          return { id: tag.name, text: tag.name } 
        });
        console.log(tlist)
        return {results: tlist}
      }
    }
  });
});
