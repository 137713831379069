// Remove fields, Views must have a hidden field with _destroy
// Add fields, Views must have a fieldset to copy 
// TODO: How to valid latitude and longitude? 
// Safari bug: Infinit Alter bug. https://stackoverflow.com/questions/48536618
// After alter, add: 
//   setTimeout(function () { $(this).focus(); }, 0);
// However, still bug if use tab to switch to next url form 
// So, we skip validation for empty string
$(document).on("turbolinks:load", function() {
  $('form').on('blur', '.shuju-url-field', function(event) {
    var url = $(this).val().trim()
    console.log("DEBUG:: start to validate URL: %s!",url)
    if(url && !isValidURL(url)){
      console.log("DEBUG:: %s is not valid URL",url)
      alert('Please enter valid URL');
      setTimeout(function () { $(this).focus(); }, 0);
    }else{
      $(this).val(url)
      console.log("DEBUG:: %s is valid URL",url)
    }
  });
  $('form').on('blur', '.shuju-image-field', function(event) {
    var image = $(this).val().trim()
    console.log("DEBUG:: start to validate image: %s!",image)
    if(image && !isValidImage(image)){
      console.log("DEBUG:: %s is not valid image",image)
      alert('Please enter valid image');
      $(this).value='';
      setTimeout(function () { $(this).focus(); }, 0);
    }else{
      $(this).val(image)
      console.log("DEBUG:: %s is valid image",image)
    }
  });
  $('form').on('blur', '.shuju-video-field', function(event) {
    var video = $(this).val().trim()
    console.log("DEBUG:: start to validate video: %s!",video)
    if(video && !isValidVideo(video)){
      console.log("DEBUG:: %s is not valid video",video)
      alert('Please enter valid video');
      setTimeout(function () { $(this).focus(); }, 0);
    }else{
      $(this).val(video)
      console.log("DEBUG:: %s is valid video",video)
    }
  });
  $('form').on('blur', '.shuju-audio-field', function(event) {
    var audio = $(this).val().trim()
    console.log("DEBUG:: start to validate audio: %s!",audio)
    if(audio && !isValidAudio(audio)){
      console.log("DEBUG:: %s is not valid audio",audio)
      alert('Please enter valid audio');
      setTimeout(function () { $(this).focus(); }, 0);
    }else{
      $(this).val(audio)
      console.log("DEBUG:: %s is valid audio",audio)
    }
  });
  $('form').on('blur', '.shuju-email-field', function(event) {
    var email = $(this).val()
    console.log("DEBUG:: start to validate email %s!", email)
    if(email && !isValidEmail(email)){
      console.log("DEBUG:: %s is not valid email",email)
      alert(`${email} is invalid. Please enter valid email. Note: no leading or trailling space allowed!`)
      setTimeout(function () { $(this).focus(); }, 0);
    }else{
      console.log("DEBUG:: %s is valid email",email)
    }
  });
  $('form').on('blur', '.shuju-phone-field', function(event) {
    var phone = $(this).val()
    console.log("DEBUG:: start to validate phone!")
    if(phone && !isValidPhone(phone)){
      console.log("DEBUG:: %s is not valid phone",phone)
      alert(`${phone} is invalid. Please enter valid phone. Note: no leading or trailling space allowed!`)
      setTimeout(function () { $(this).focus(); }, 0);
    }else{
      console.log("DEBUG:: %s is valid phone",phone)
    }
  });
  $('form').on('blur', '.shuju-ip-field', function(event) {
    var ip = $(this).val()
    console.log("DEBUG:: start to validate IP!")
    if(ip && !isValidIP(ip)){
      console.log("DEBUG:: %s is not valid IP",ip)
      alert(`${ip} is invalid. Please enter valid ip. Note: no leading or trailling space allowed!`)
      setTimeout(function () { $(this).focus(); }, 0);
    }else{
      console.log("DEBUG:: %s is valid IP",ip)
    }
  });
  // blur not fired for composite type
  $('form').on('blur', '.shuju-gps-field', function(event) {
    console.log("DEBUG:: start to validate gps!")
    var gps = $(this).val()
    if(gps && !isValidGPS(gps)){
      console.log("DEBUG:: %s is not valid gps",gps)
      alert(`${gps} is invalid. Please enter valid gps. Note: no leading or trailling space allowed!`)
      setTimeout(function () { $(this).focus(); }, 0);
    }else{
      console.log("DEBUG:: %s is valid gps",gps)
    }
  });
});



// https://stackoverflow.com/questions/5717093
function isValidURL(url){
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
// Very low: 12345678901234567890123
//   '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return pattern.test(url);
}

function isValidImage(image){
  return isValidURL(image) // could be local path?
  //return true
}
function isValidVideo(video){
  return isValidURL(video) // could be local path?
  //return true
}
function isValidAudio(audio){
  return isValidURL(audio) // could be local path?
  //return true
}

function isValidEmail(email){
  // Same with model, but \z not work, use ^ and $
  //var regex=/\A([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})\z/i
  var regex=/^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i
  return regex.test(email)
}
function isValidPhone(phone){
  // Same with model
  var regex=/^\+?(?:[0-9][\s\.-]?){6,14}[0-9]$/ 
  return regex.test(phone)
}
function isValidIP(ip){
  // IPv4 only: https://stackoverflow.com/questions/4460586
  var regex=/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
  return regex.test(ip)
}
function isValidGPS(gps){
  // IPv4 only: https://stackoverflow.com/questions/4460586
  return true 
}
