// https://www.kohrvid.com/blog/posts/using-quilljs-with-rails-6
// https://medium.com/@technoblogueur/rails-6-and-webpacker-what-you-need-to-know-e3534fded7ff
// quill.js
import Quill from 'quill';
// quill.snow.js, otherwise, UI won't showup correctly
import 'quill/dist/quill.snow.css';
// tooblar
export default Quill;


// By default, the inline code and code-block buttons use the same icon. 
// Overwrite this with the SVG code for a new icon design.
var icons = Quill.import('ui/icons');
icons['code'] = `<svg class="bi bi-code" width="1.5em" height="1.5em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.854 4.146a.5.5 0 010 .708L2.707 8l3.147 3.146a.5.5 0 01-.708.708l-3.5-3.5a.5.5 0 010-.708l3.5-3.5a.5.5 0 01.708 0zm4.292 0a.5.5 0 000 .708L13.293 8l-3.147 3.146a.5.5 0 00.708.708l3.5-3.5a.5.5 0 000-.708l-3.5-3.5a.5.5 0 00-.708 0z" clip-rule="evenodd"/></svg>`;
// NOTE: quill doesn't support image with URL. We add a new with name 'image_link'
// Howto: https://github.com/quilljs/quill/issues/2044
// Use font-awesome icon: https://github.com/quilljs/quill/issues/1099.
// Remember to install Fontawosome5 
icons['image'] = '<i class="far fa-file-image" aria-hidden="true"></i>';
//icons['image_link'] = '<i class="far fa-image" aria-hidden="true"></i>';
icons['image_link'] = '<i class="far fa-image" aria-hidden="true"></i>';


$(document).on("turbolinks:load", function() {
  var quill = new Quill('#editor', getQuillOptions()); 
  $('form').on('submit',function(event) {
    // JQuery: OK 
    // Error: quill Invalid Quill container #editor, if no quill editor on that page.
    if(typeof quill.root !== "undefined"){
      $("input.rich-text-content").val(quill.root.innerHTML)
    }
    // OR
    //$(".rich-text-content").val(quill.root.innerHTML)
    // OR
    //var body = $("input[class*='rich-text-content']");
    //$(body).val(quill.root.innerHTML)
    //JS: OK
    //var body = document.querySelector('input[class*=rich-text-content]');
    //body.value = quill.root.innerHTML
  });
});

function getQuillOptions(){
  // https://quilljs.com/docs/formats/
  var toolbarOptions = [
    [{ 'font': [] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }, {'size': []}],
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons

    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }, { 'align': [] }],          // outdent/indent

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme

    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript

    ['link', 'image', 'image_link', 'video'],

    ['code', 'code-block', 'blockquote', 'formula'],
    //['blockquote', 'code-block'],
    //[{ 'direction': 'rtl' }],                         // text direction
    //[{ 'header': 1 }, { 'header': 2 }],               // custom button values
    ['clean']                                         // remove formatting button
  ];
  var options = {
    debug: 'info',
    modules: {
      // Not work, what to import?
      //toolbar: '#toolbar' 
      // toolbar: toolbarOptions,
      toolbar: {
          container: toolbarOptions,
          handlers: {
              image_link: imageHandler
          }
      }
    },
    placeholder: 'Compose an epic...',
    //readOnly: true,
    theme: 'snow'
  };
  return options
}

// More like link
function imageHandler() {
  const tooltip = this.quill.theme.tooltip;
  const originalSave = tooltip.save;
  tooltip.save = function() {
    const range = this.quill.getSelection(true);
    const value = this.textbox.value;
    if (value) {
      this.quill.insertEmbed(range.index, 'image', value, 'user');
    }
    tooltip.save = originalSave;
  };
  tooltip.edit('image');
}
// Popup window 
/*
function imageHandler() {
  var range = this.quill.getSelection();
  var value = prompt('Enter the image URL');
  if(value){
    this.quill.insertEmbed(range.index, 'image', value, Quill.sources.USER);
  }
}
*/

