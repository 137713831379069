// Utils. 
// Rails-ujs: Unlike the version with jQuery, all custom events return only one parameter: event.
// https://stackoverflow.com/questions/44520365/
// https://edgeguides.rubyonrails.org/working_with_javascript_in_rails.html#rails-ujs-event-handlers

// NOTE: Binding click event for dynamically generated elements. 
// https://stackoverflow.com/questions/19371141

///////////////////////////////////////////////////////////////
// AJAX toggle/switch 
//
// button-to with ajax-toggle. After success, toggle all siblings
$(document).on("turbolinks:load", function() {
  // NOTE: Flip all element with "ajax-toggle" class in the same div 
  // It requires that styple is used for the element with "ajax-toggle" class to control visibility. 
 //  Do not use other method e.g. bootstrap class d-none/d-inline-block, as toogle won't change the class.
  //$('.ajax-toggle').on('ajax:success', function(event) {
  $(document).on('ajax:success', '.ajax-toggle',function(event) {
    console.log("ajax-toggle success!")
    $(this).toggle() 
    $(this).siblings(".ajax-toggle").toggle()
  }).on('ajax:error', function(event) {
    const [error, status, xhr] = event.detail;
    return console.log("ERROR status=" + status + ", error=" + error);
  });
});

// Toggle by ID 
$(document).on("turbolinks:load", function() {
  //$('.ajax-toggle-id').on('ajax:success', function (e) {
  $(document).on('ajax:success','.ajax-toggle-id', function (e) {
    console.log("ajax-toggle-id after AJAX success")
    let tid = $(this).attr('toggle-id'); 
    console.log(tid);
    $("#"+tid).toggle(); 
  });
});

// Remove by ID 
$(document).on("turbolinks:load", function() {
  //$('.ajax-toggle-id').on('ajax:success', function (e) {
  $(document).on('ajax:success','.ajax-remove-id', function (e) {
    console.log("ajax-remove-id after AJAX success")
    let tid = $(this).attr('remove-id'); 
    console.log(tid);
    $("#"+tid).remove(); 
  });
});

// button-to with fsm-switch. After success, hide "curr-state", show "next-state"
// finite-state-machine switch
$(document).on("turbolinks:load", function() {
  //$('.fsm-switch').on('ajax:success', function (e) {
  $(document).on('ajax:success', '.fsm-switch', function (e) {
    console.log("FSM switch after AJAX success")
    let cid = $(this).attr('curr-state'); 
    let nid = $(this).attr('next-state'); 
    console.log(cid);
    console.log(nid);
    $("#"+cid).toggle()
    $("#"+nid).toggle()
  });
});

// Hide ID, show ID, move to another ID
$(document).on("turbolinks:load", function() {
  //$('.switch-move').on('ajax:success', function (e) {
  $(document).on('ajax:success', '.switch-move', function (e) {
    console.log("switch-move after AJAX success")
    let hide = $(this).attr('hide-id'); 
    let show = $(this).attr('show-id'); 
    $("#"+hide).hide()
    $("#"+show).show()
    let sid = $(this).attr('src-id'); 
    let did = $(this).attr('dst-id'); 
    console.log(`show ${show}, hide ${hide}, move ${sid} to ${did}`);
    //let record = $("[data-id=\"" + sid + "\"]");
    let record = $("#"+sid);
    $("#"+did).prepend(record) 
  });
});


///////////////////////////////////////////////////////////////
// Links
//
// Togggle another part specified by ID when clicked 
$(document).on("turbolinks:load", function() {
  //  $('a.click-toggle-id').click(function (e) {
  // NOT WORK $('a').on('click','.click-toggle-id',function (e) {
  $(document).on('click','a.click-toggle-id',function (e) {
    console.log("click-toggle-id clicked")
    // prevent the default click action
    e.preventDefault();
    let tid = $(this).attr('toggle-id'); 
    console.log(tid)
    $("#"+tid).toggle(); 
  });
});

// Load more button. Following https://josephndungu.com/tutorials/rails-load-more-results-using-jquery-ajax
// Improvement: multiple in the same page. The HTML layout must be 
//   <div class="load-more-container" id="section_id">
//     <div class="load-more-records">
//       <div class="record" date-id=object_id>
//       </div>
//       <div class="record" date-id=object_id>
//       </div>
//     </div>
//     <div class="load-more-button">
//    	 <img class="loading-gif"></img> 
//       <a class="load-more">load more </a> 
//     </div>
//   </div>
// Section is optional unless multiple containers on the same page
$(document).on("turbolinks:load", function() {
  // when the load more link is clicked
  //$('a.load-more').click(function (e) {
  $(document).on('click','a.load-more',function (e) {
    console.log("Load-more clicked")
    // prevent the default click action
    e.preventDefault();
    // hide load more link
    //$(this).siblings('.load-more').hide();
    $(this).parent().children('.load-more').hide(); // itself or siblings
    //$('.load-more').hide();
    // show loading gif
    $(this).siblings('.loading-gif').show();
    // Section 
    var section = $(this).parent().parent()
    var section_id = section.attr('id')
    console.log(`Section ID: ${section_id}`) 
    // records 
    var cobj = $(this).parent().siblings(".load-more-records") // record object
    // get the last id and save it in a variable 'last-id'
    var last_id = cobj.find('.record').last().attr('data-id');
    console.log(`Last ID: ${last_id}`) 
    // make an ajax call passing along our last user id
    $.ajax({
      // make a get request to the server
      type: "GET",
      // get the url from the href attribute of our link
      url: $(this).attr('href'),
      // send the last id to our rails app
      data: {
        record: last_id,
        section: section_id
      },
      // the response will be a script
      dataType: "script",
      // upon success 
      success: function (data,status,xhr) {
        console.log("AJAX done")
        // hide the loading gif. TODO: how about mutliple?
        $('.loading-gif').hide();
        // show our load more link
        $('.load-more').show();
      }
    });
  });
}); 

///////////////////////////////////////////////////////////////
// parameterize value of a filed and put it into another field
require('./parameterize.js')

$(document).on("turbolinks:load", function() {
  $('form').on('input', '.parameterize-to', function(event) {
    console.log("parameterize value updated") 
    let tid = $(this).attr('dst-id'); 
    console.log("DEBUG:: The dst ID is: %s!",tid)
    let content= $(this).val().trim()
    console.log("DEBUG:: The form value is: %s!",content)
    let slug = parameterize(content)
    console.log("DEBUG:: Set value of %s to is: %s!",tid,content)
    $("#"+tid).val(slug); 
  });
});

///////////////////////////////////////////////////////////////
// Tooltips
// Not a function error. 
// Not necessary? Could be enabled by other plugin
//$(document).on("turbolinks:load", function() {
//  $('[data-toggle="tooltip"]').tooltip(); 
//});
