// Script for dynamic record array elements 
// The record element HTML should be like 
//  <div> 
//    <div class='record_value'>
//      xxx_field(class: 'record-value-item')
//      link_to("+","#",class: "add_record_array_element");
//      link_to("-","#",class: "remove_record_array_element");
//    </div> 
//    ......
//  </div> 
$(document).on("turbolinks:load", function() {
  // Add an item. NOTE: we add time as prefix to ID to make sure ID are not the same 
  $('form').on('click', '.add_record_array_element', function(event) {
    console.log("DEBUG:: add record array element!") 
    // Find the first parent with class "record_array_element"
    item = $(this).parents('.record_array_element').first()
    // Record cell
    cell = item.parent();
    // Clone
    new_item = item.clone() 
    // Add prefix to all IDs to make them uniq. https://stackoverflow.com/questions/16465694
    var time = new Date().getTime();
    new_item.find("[id]").each(function() { // Select all elements with an ID
       var target = $(this);
       var id = target.attr("id");         // Get the ID
       target.attr("id", time+"_"+id);     // Set the id
    });
    // Append
    cell.append(new_item) 
    return event.preventDefault();
  });
  // Remove an item. NOTE: Can't remove the last one, otherwise, update has no effect.
  $('form').on('click', '.remove_record_array_element', function(event) {
    console.log("DEBUG:: remove record array element!") 
    item = $(this).parents('.record_array_element').first()
    cell = item.parent();
    // Number of items
    n_items = cell.children('div.record_array_element').length
    if(n_items>1) { // remove if not the last one
      item.remove() 
    } else {        // set value to "", otherwise, update no effect.
      vfield = item.children('.record-value-item:first')
      vfield.val("")
    }  
    return event.preventDefault(); // Stop default link action
  });
});
// Script for solving checkbox array. Disable hidden field if checked. 
$(document).on("turbolinks:load", function() {
  $('form').on('change','.boolean_array', function(event) {
    console.log(`DEBUG:: Bool ${this.id} Updated!`) 
    hid = this.id+"_hidden";
    if(this.checked){
      console.log(`DEBUG:: checked! Disable ${hid}`) 
      //$(`#hid`).attr('disabled',true)
      //$("#"+hid).disabled=true; 
      $("#"+hid).attr('disabled',true) 
    } else { 
      console.log(`DEBUG:: ${this.id} unchecked!`)
      //$(`#hid`).removeAttr("disabled");
      //$("#"+hid).disabled=false; 
      $("#"+hid).attr('disabled',false) 
    }
  })
});
