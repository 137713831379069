// NOTE: The address has fields of country, province, city, county, town selector or text. 
//   The class name is shuju-<field>-field 
//   The ID is: prefix_country
//              prefix_province_sel, prefix_province_text
//              prefix_city_sel, prefix_city_text
//              prefix_county_sel, prefix_county_text
//              prefix_town_sel, prefix_town_text
//   The name is: prefix[country]
//                prefix[province], prefix[province_hidden], etc.
// NOTE: prefix could contain "_": 1. from table column names, 2. from nested e.g. l1[l2[f]] -> l1_l2_f. 
// TODO: Need to solve it happen. E.g. a_b_city, the name should be a_b[city] or a[b[city]]?
$(document).on("turbolinks:load", function() {
  // NOTE: how about multiple elements with shuju-country-field class?
  $('form').on('change', '.shuju-country-field', function(event) {
    // get ID
    var id = $(this).attr('id'); var prefix = getPrefixFromCountryID(id);
    var c= $(this).val()
    console.log("DEBUG:: Country selected: %s!",c)
    updateProvinceList(prefix,c);  
  });
  // NOTE: Event? change will not set city if default province, click works but dup work if already loaded.
  // NOTE: click may not work if change
  $('form').on('change', '.shuju-province-field', function(event) {
    var id = $(this).attr('id'); var prefix = getPrefixFromProvinceID(id);
    var cid = genCountryID(prefix); var c= $('#'+cid).val();
    var p= $(this).val();
    console.log("DEBUG:: Province selected: %s!",p)
    updateCityList(prefix,c,p);  
  });
  $('form').on('change', '.shuju-city-field', function(event) {
    var id = $(this).attr('id'); var prefix = getPrefixFromCityID(id);
    var cid = genCountryID(prefix); var c= $('#'+cid).val();
    var pid = genProvinceID(prefix); var p= $('#'+pid).val();
    var city = $(this).val()
    console.log("DEBUG:: City selected: %s!",city)
    updateCountyList(prefix,c,p,city);  
  });
  $('form').on('change', '.shuju-county-field', function(event) {
    var id = $(this).attr('id'); var prefix = getPrefixFromCountyID(id);
    var cid = genCountryID(prefix); var c= $('#'+cid).val();
    var pid = genProvinceID(prefix); var p= $('#'+pid).val();
    var cityid = genCityID(prefix); var city= $('#'+cityid).val();
    var county= $(this).val()
    console.log("DEBUG:: County selected: %s!",county)
    updateTownList(prefix,c,p,city,county);  
  });
});
function updateProvinceList(prefix,country){
  $.getJSON("/address/provinces.json", {
    country: country
  }, function (json) {
    if (json === undefined || json.length == 0) {
      console.log("DEBUG:: Empty province list") 
      switchToText(prefix,"province")
      switchToText(prefix,"city")
      switchToText(prefix,"county")
      switchToText(prefix,"town")
    } else { 
      console.log("DEBUG:: Update province list") 
      switchToSel(prefix,"province")
      switchToSel(prefix,"city")
      switchToSel(prefix,"county")
      switchToSel(prefix,"town")

      pid = genProvinceID(prefix);
      $("#"+pid).empty() 
      for (var i = 0; i < json.length; i++) {
        ///console.log("DEBUG:: Get province: %s", json[i].name);
        $("<option/>").attr("value", json[i].name).text(json[i].name).appendTo($("#"+pid)); 
      }
      updateCityList(prefix,country,json[0].name)
    }
  });
}
function updateCityList(prefix,country,province){
  $.getJSON("/address/cities.json", {
    country: country,
    province: province
  }, function (json) {
    if (json === undefined || json.length == 0) {
      console.log("DEBUG:: Empty city list") 
      switchToText(prefix,"city")
      switchToText(prefix,"county")
      switchToText(prefix,"town")
    } else { 
      console.log("DEBUG:: Update city list") 
      switchToSel(prefix,"city")
      switchToSel(prefix,"county")
      switchToSel(prefix,"town")
     
      cid = genCityID(prefix)
      $("#"+cid).empty()
      for (var i = 0; i < json.length; i++) {
        //console.log("DEBUG:: Get city: %s", json[i].name);
        $("<option/>").attr("value", json[i].name).text(json[i].name).appendTo($("#"+cid)); 
      }
      updateCountyList(prefix,country,province,json[0].name)
    }
  });
}

function updateCountyList(prefix,country,province,city){
  $.getJSON("/address/counties.json", {
    country: country,
    province: province,
    city: city,
  }, function (json) {
    if (json === undefined || json.length == 0) {
      console.log("DEBUG:: Empty county list") 
      switchToText(prefix,"county")
      switchToText(prefix,"town")
    } else { 
      console.log("DEBUG:: Update county list") 
      switchToSel(prefix,"county")
      switchToSel(prefix,"town")

      cid = genCountyID(prefix)
      $("#"+cid).empty()
      for (var i = 0; i < json.length; i++) {
        //console.log("DEBUG:: Get county: %s", json[i].name);
        $("<option/>").attr("value", json[i].name).text(json[i].name).appendTo($("#"+cid)); 
      }
      updateTownList(prefix,country,province,city,json[0].name)
    }
  });
}

function updateTownList(prefix,country,province,city,county){
  $.getJSON("/address/towns.json", {
    country: country,
    province: province,
    city: city,
    county: county,
  }, function (json) {
    if (json === undefined || json.length == 0) {
      console.log("DEBUG:: Empty town list") 
      switchToText(prefix,"town")
    } else { 
      console.log("DEBUG:: Update town list") 
      switchToSel(prefix,"town")
      
      tid = genTownID(prefix)
      $("#"+tid).empty()
      for (var i = 0; i < json.length; i++) {
        //console.log("DEBUG:: Get town: %s", json[i].name);
        $("<option/>").attr("value", json[i].name).text(json[i].name).appendTo($("#"+tid)); 
      }
    }
  });
}

// For fields: province, city, county, town
// Each has a selction or text with id as address_field_text/sel. One is used and the other is hidden
// The class name is address[field] for used one and address[field_hidden] for hidden one
function switchToSel(prefix,field){
  sid = `#${prefix}_${field}_sel`; 
  tid = `#${prefix}_${field}_text`; 

  // e.g. 地址[][province] -> 地址[][province_hidden]
  var old_name = $(tid).attr("name"); 
  var new_name = old_name.slice(0,-1)+"_hidden]"
  $(tid).attr("name", new_name); 
  $(tid).hide();

  // e.g. 地址[][province_hidden] -> 地址[][province]
  var old_name = $(sid).attr("name"); 
  var new_name = old_name.replace(new RegExp('_hidden]$'),']')
  $(sid).attr("name", new_name); 
  $(sid).show(); 
}
function switchToText(prefix,field){
  sid = `#${prefix}_${field}_sel`; 
  tid = `#${prefix}_${field}_text`; 

  var old_name = $(sid).attr("name"); 
  var new_name = old_name.slice(0,-1)+"_hidden]"
  $(sid).attr("name", new_name); 
  $(sid).hide();

  var old_name = $(tid).attr("name"); 
  var new_name = old_name.replace(new RegExp('_hidden]$'),']')
  $(tid).attr("name", new_name); 
  $(tid).show(); 
}
// The ID for address is prefix_country, prefix_province_sel, prefix_city_sel etc 
function getPrefixFromCountryID(id){ 
  return id.replace(new RegExp('_country$'),'');
}
function getPrefixFromProvinceID(id){ 
  return id.replace(new RegExp('_province_sel$'),'');
}
function getPrefixFromCityID(id){ 
  return id.replace(new RegExp('_city_sel$'),'');
}
function getPrefixFromCountyID(id){ 
  return id.replace(new RegExp('_county_sel$'),'');
}
function getPrefixFromTownID(id){ 
  return id.replace(new RegExp('_town_sel$'),'');
}
function extractPrefix(id){ 
  return id.split("_")[0];
}
function genCountryID(prefix){ 
  return `${prefix}_country`
} 
function genProvinceID(prefix){ 
  return `${prefix}_province_sel`
} 
function genCityID(prefix){ 
  return `${prefix}_city_sel`
} 
function genCountyID(prefix){ 
  return `${prefix}_county_sel`
} 
function genTownID(prefix){ 
  return `${prefix}_town_sel`
} 
