// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

///////////////////////////////////////////////////////////////////
// css
import 'stylesheets/application'
// cofffe
// We have move tables.coffee to tables.js. So don't need this. 
// But we could add it back later to support coffee
require('jquery')
//require("../coffee/application.coffee")

// bootstrap
import 'bootstrap/dist/js/bootstrap';
// font-awesome
import "@fortawesome/fontawesome-free/js/all";

// chart
require("chartkick")
require("chart.js")

// quill
require('./quill_editor.js')

// clipboard
var ClipboardJS = require('clipboard')

// select2
import $ from 'jquery';
require('select2')
import 'select2/dist/css/select2.min.css'
import "select2-bootstrap-theme/dist/select2-bootstrap.min.css";
//import 'select2/dist/js/i18n/en.js';
//require('select2/dist/js/i18n/zh-CN.js');

//require('crypto-js') 
var CryptoJS = require("crypto-js");

///////////////////////////////////////////////////////////////////
// DEBUG purpose, I want to know when will document.ready skipped.
$(document).on("turbolinks:load", function() {
  console.log("DEBUG:: turbolinks.load!")
  var clipboard = new ClipboardJS('.clipboard-btn');
  //console.log(clipboard);
});

$(document).ready(function() {
  console.log("DEBUG:: document.ready!");
});

// Load all *.js in packs directory 
require('./utils.js')
require('./tables.js')
require('./records.js')
require('./validators.js')
require('./cell.js')
require('./address.js')
require('./tag.js')
