$(document).on("turbolinks:load", function() {
  // Remove fields, Views must have a hidden field with _destroy
  $('form').on('click', '.remove_fields', function(event) {
    console.log("DEBUG:: remove fields!")
    $(this).prev('input[type=hidden]').val('1');
    $(this).closest('fieldset').hide();
    return event.preventDefault();
  });
  // Add fields, Views must have a fieldset to copy 
  $('form').on('click', '.add_fields', function(event) {
    console.log("DEBUG:: add fields!")
    var regexp, time;
    time = new Date().getTime();
    regexp = new RegExp($(this).data('id'), 'g');
    $(this).before($(this).data('fields').replace(regexp, time));
    return event.preventDefault();
  });

  // Enum selector
  $('form').on('change', '.shuju-value-type-selector', function(event) {
    console.log("DEBUG:: value type selector!")
    var currOpt = $(this).val().trim()
    console.log("DEBUG:: select type %s!",currOpt)
    enum_selector = $(this).next('.enum_selector').first()
    if(currOpt=='enum'){
      console.log("DEBUG:: show enum selector!")
      enum_selector.show() 
      // enabled selector
      e_sel = enum_selector.children('select').first()
      e_sel.prop('disabled',false) 
      // disable default 
      enum_selector.children('input:hidden').prop('disabled',true) 
      $.getJSON('/enums.json', function(enums) {
        e_sel.empty()  // remove all options if exists
        for (var i=0; i<enums.length; i++){   // set options
          e= enums[i]; 
          value = `{"enum_id"=>${e.id}}`;
          e_sel.append($('<option>', {
            value: value,
            text: e.name
          }));
        }
      });
    } else {
      console.log("DEBUG:: hide enum selector!")
      enum_selector.hide() 
      // disable selector
      enum_selector.children('select').prop('disabled',true) 
      // enable default 
      enum_selector.children('input:hidden').prop('disabled',false) 
    }
  });
});
